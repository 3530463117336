@font-face {
    font-family: 'CascadiaCode';
    src: url(./assets/fonts/CascadiaCode.ttf);
    display: swap;
}

* {
    font-family: 'CascadiaCode', monospace;
    box-sizing: border-box;
}

html {
    height: 100%;
    margin: 0;
}

body {
    height: 100%;
    margin: 0;
}

#root {
    height: 100%;
    margin: 0;
}